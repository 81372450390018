import { api } from "@/utils/api.js";

const adminModule = {
    namespaced: true,
    state: {
        payments: [],
        admins: [],
        userAbilities: {},
    },
    actions:{
        async fetchUserAbilities({ commit }) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.get("admin/user-abilities", {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                commit("SET_USER_ABILITIES", response.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async fetchPayments({ commit }) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.get("admin/mpesa-payments", {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                commit("SET_PAYMENTS", response.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async getAllAdmins({ commit }) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.get("admin/list-admins", {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                commit("SET_ADMINS", response.data.data);
            } catch (error) {
                console.log(error);
            }
        },

        async addAdmin({ commit }, data) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.post("admin/add-admin", data, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },

        async removeAdmin({ commit }, data) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.post(`admin/remove-admin`, data, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },

        async addSuperAdmin({ commit }, data) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.post("admin/add-super-admin", data, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },

        async removeSuperAdmin({ commit }, data) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.post(`admin/remove-super-admin`, data, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },

        async resetPassword({ commit }, data) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await api.post(`admin/reset-password`, data, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations:{
        SET_PAYMENTS(state, payments) {
            state.payments = payments;
        },
        SET_ADMINS(state, admins) {
            state.admins = admins;
        },
        SET_USER_ABILITIES(state, userAbilities) {
            state.userAbilities = userAbilities;
        },
    }
}
export default adminModule;