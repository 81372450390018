import axios from "axios";

let appUrl = process.env.VUE_APP_URL;

export const api = axios.create({
  baseURL: `${appUrl}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});
