import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import { quillEditor } from "vue3-quill";
import VueDatatable from "@bhplugin/vue3-datatable";
import { ElMessage, ElMessageBox } from 'element-plus'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faCreditCard, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons";

library.add(faCreditCard, faClock, faUsers, faUserTie, faCircleDot);

createApp(App)
	.use(store)
	.use(router)
	.use(quillEditor)
	.use(VueDatatable)
	.use(ElementPlus)
	.use(ElMessage)
	.use(ElMessageBox)
	.component('font-awesome-icon', FontAwesomeIcon)
	.use(library)
	.mount("#app");
