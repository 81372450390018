import { api } from "@/utils/api.js";

const paymentModule = {
	namespaced: true,
	state: {
		payments: [],
		hybridSubscriptions: [],
	},

	actions: {
		async getPayments({ commit }) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.get("/list-mpesa-payments", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});
				commit("setPayments", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async changePaymentStatus({ commit }, payment) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.post(
					`/update-mpesa-payment-status/${payment.id}`,
					{
						status: payment.status,
					},
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
				commit("setPayments", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},

		async fetchHybridSubscriptions({ commit }, payment) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.get("/admin/hybrid-subscriptions", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});

				commit("setHybridSubscriptions", response.data.data);
			} catch (error) {
				console.log(error);
			}
		},
	},

	mutations: {
		setPayments(state, payments) {
			state.payments = payments;
		},

		setHybridSubscriptions(state, hybridSubscriptions) {
			state.hybridSubscriptions = hybridSubscriptions;
		}
	},
};

export default paymentModule;
