import { createStore } from "vuex";
import userModule from "./Auth/userModule.js";
import paymentModule from "./Auth/paymentModule.js";
import checkinsModule from "./Others/checkinsModule.js";
import adminModule from "./Auth/adminModule.js";
import blogModule from "./Blog/blogModule.js";

export default createStore({
  modules: {
    userModule: userModule,
    paymentModule: paymentModule,
    checkinsModule: checkinsModule,
    adminModule: adminModule,
    blogModule: blogModule,
  },
});
