import { api } from "@/utils/api.js";

const userModule = {
	namespaced: true,

	state: {
		errors: null,
		error: null,
        successStatus: null,
		user: null,
		userDetails: null,
		allUsers: [],
		gymWorkouts: [],
	},
	actions: {
		async login({ commit }, data) {
			try {
				const response = await api.post("/admin/login", {
					email: data.email,
					password: data.password,
				});
				console.log(response);
				const token = response.data.token;
				const user = response.data.user;

				localStorage.setItem("access_token", token);
				localStorage.setItem("user", JSON.stringify(user));
			} catch (error) {
				const errors = error.response.data.errors;
				const e = error.response.data.message;

				commit("setErrors", errors);
				commit("setError", e);

				setTimeout(() => {
					commit("setErrors", null);
					commit("setError", null);
				}, 5000);
			}
		},

		async getUser({ commit }) {
			try {
				const token = localStorage.getItem("access_token");
				const response = await api.get("/user", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});
				const user = response.data.user;

				commit("setUser", user);
			} catch (error) {
				console.log(error);
			}
		},

		async logout({ commit }) {
			localStorage.removeItem("access_token");
			commit("setUser", null);
		},

		// All Users
		async getAllUsers({ commit }) {
			const token = localStorage.getItem("access_token");
			try {
				const response = await api.get("/all-users", {
					headers: {
						Authorization: "Bearer " + token,
					},
				});
				const users = response.data.data;

				commit("setAllUsers", users);
			} catch (error) {
				console.log(error);
			}
		},

		// user programs
		async getUserDetails({ commit }, userId) {
			const token = localStorage.getItem("access_token");
			try {
				const response = await api.get(`/user-programs`, {
					headers: {
						Authorization: "Bearer " + token,
					},

					params: {
						user_id: userId,
					},
				});

				const programs = response.data.data;
				commit("setUserDetails", programs);
			} catch (error) {
				console.log(error);
			}
		},

		// updateHybridProgram
		async updateHybridProgram({ commit }, userData) {
			const token = localStorage.getItem("access_token");
			try {
				const response = await api.post(`/update-hybrid-program`, userData, {
					headers: {
						Authorization: "Bearer " + token,
					},
				});
                const successStatus = response.data.status

                commit("setSuccessStatus", successStatus);

                setTimeout(() => {
                    commit("setSuccessStatus", null);
                }, 5000);

			} catch (error) {
                console.log(error);
				const e = error.response.data.status;

				commit("setSuccessStatus", e);

				setTimeout(() => {
					commit("setSuccessStatus", null);
				}, 5000);
			}
		},

        // Activate hybrid program
        async activateHybridProgram({ commit }, userData) {
            const token = localStorage.getItem("access_token");

			try {
                const response = await api.post(`/activate-hybrid-program`, userData, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                });
                const successStatus = response.data.status

                commit("setSuccessStatus", successStatus);

                setTimeout(() => {
                    commit("setSuccessStatus", null);
                }, 5000);

            } catch (error) {
                console.log(error);
                const e = error.response.data.status;

                commit("setSuccessStatus", e);

                setTimeout(() => {
                    commit("setSuccessStatus", null);
                }, 5000);
            }
        },

		async listGymWorkouts({ commit }) {
			const token = localStorage.getItem("access_token");
			try {
				const response = await api.get(`/list-gym-workouts`, {
					headers: {
						Authorization: "Bearer " + token,
					},
				});

				const gymWorkouts = response.data.data;
				commit("setGymWorkouts", gymWorkouts);
			} catch (error) {
				console.log(error);
			}
		},
	},
	getters: {},
	mutations: {
        setSuccessStatus(state, successStatus) {
            state.successStatus = successStatus;
        },
		setErrors(state, errors) {
			state.errors = errors;
		},
		setError(state, error) {
			state.error = error;
		},
		setUser(state, user) {
			state.user = user;
		},
		setUserDetails(state, programs) {
			state.userDetails = programs;
		},
		setAllUsers(state, users) {
			state.allUsers = users;
		},
		setGymWorkouts(state, gymWorkouts) {
			state.gymWorkouts = gymWorkouts;
		},
	},
};
export default userModule;
